:root {
  --brand-primary-color: #43c3f6;
  --field-label-color: #dddddd;

  // background color
  --primary-bg-color: #E0F0FF;
  --primary-dark-bg-color: #4AACE9;
  --secondary-bg-color: #FFFFFF;

  // button background color
  --button-bg-color: #4AACE9;
  --button-color-green: #04BB00;

  // text or font color
  --primary-font-color: #111111;
  --secondary-font-color: #FFFFFF;
  --faded-font-color: #FFFFFF;
  --link-font-color: #0404bc;
  --link-font-hover-color: #0e8bde;

  // border-radius
  --border-radius: 5px;

  --grey-border: 1px solid #dddddd;

  // custom fonts
  --regular-font: 'hk-regular';
  --bold-font: 'hk-bold';

  --input-background-color: #ffffff;
  --input-content-color: #666666;
  --input-border-color: #dddddd;

  --generic-gap-small: 5px;
  --generic-gap-medium: 10px;
  --generic-gap-large: 15px;

  --component-header-extra-large: 32px;
  --component-header-large: 30px;
  --component-header-medium: 26px;
  --component-header-small: 20px;

  --font-size-extra-large: 24px;
  --font-size-large: 20px;
  --font-size-medium: 16px;
  --font-size-small: 12px;
  --font-size-extra-small: 8px;

  --table-backgroud-color-grey: #F1F1F1;

  --card-color-danger: #DD2F30;

  --card-font-medium: 20px;
  --card-font-small: 16px;
  --card-font-extra-small: 14px;

  --color-success: #2dd36f;
  --color-danger: #cc3333;

  --button-font-desktop: 19px;

  --sub-navbar-color: #f1f1f1;
}

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

body {
  overscroll-behavior: none;
}

app-header {
  display: block;
  padding-top: var(--status-bar-height);
  background: var(--hf-primary-bg);
}

hf-toast {
  padding-top: var(--status-bar-height);
}

.sub-navbar {
  min-height: 68px;
  padding: 10px 15px;
  background-color: var(--sub-navbar-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tooltip-title {
  font-family: var(--bold-font);
  font-size: var(--component-header-medium);
}

.heading {
  margin: 0;
  padding: var(--generic-gap-medium) 0;
  font-family: var(--bold-font);
}

.skeleton-box {
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 1s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.sub-nav-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 15px;
  background: var(--hf-main-color);

  .sub-nav-bar-title {
    display: flex;
    align-items: center;

    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
  }

  img {
    filter: brightness(0) invert(1);
  }
}

.home-custom-heading {
  font-size: 22px;
  line-height: 26.4px;
  font-weight: 500;
}

.home-custom-heading-2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
}

.home-custom-body-1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
}

@media (max-width: 832px) {
  .home-custom-heading {
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 700;
  }

  .home-custom-body-1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
  }

  .home-custom-heading-2 {
    font-size: 16px;
    line-height: 19.2px;
  }
}

@media (min-width: 701px) {
  .sub-nav-bar {
    padding: 40px 15px 10px;
  }
}
